import React, { Component } from "react"
import { Link } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import SEO from "../components/seo"

const styles = theme => ({})

export class template extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="template"
          keywords={[`adc`, `American Dream Construction`]}
        />
        <div>.</div>
      </Layout>
    )
  }
}

export default withStyles(styles, { withTheme: true })(template)
